<template>
<div class="restaurant-contract">
    <div class="filter-bar">
        <div class="p-fluid p-grid">
            <div class="p-col-12 p-md-6 p-sm-6">
                <div style="display: flex;">
                    <label style="min-width: 120px" for="restaurant_name">Restaurant name:</label>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <AutoComplete inputClass="custom-autocomplete-icon" v-model="selectedRestaurant" :suggestions="filteredRestaurant" @complete="searchRestaurant($event)" @item-select="onRestaurantNameSelect($event)" field="restaurant_name" />
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-md-6 p-sm-6">
                <div style="display: flex;">
                    <label style="min-width: 120px" for="contract_name">Contract:</label>
                    <Dropdown style="width: 100%" v-model="selectedContract" :options="contract_list" optionLabel="contract_name" >
                    </Dropdown>
                </div>
            </div>
        </div>        
        <div class="p-fluid p-grid" style="margin-top: 0px">
            <label for="season" style="width: 120px" class="p-col-12 p-md-1 p-lg-1">Contract period:</label>
            <div class="p-col">
                <Dropdown v-model="selectedPeriod" :options="contractPeriod_List" optionLabel="validity"></Dropdown> 
            </div>
            <div class="p-col-fixed">
                <Button label="Update Contract" @click="openRestaurantContractForm()" style="width: 8.7rem" class="p-button-sm" /> 
            </div>
        </div>
    </div>

    <div class="p-grid restaurant-menu">
        <div class="p-col-12 p-md-6 p-lg-6">
            <Panel class="rate-panel" header="Menu rate">
                <DataTable
                    id="Menu-Price"
                    :value="menuPriceList"
                    responsiveLayout="scroll"
                    scrollHeight="277px"
                    selectionMode="single"
                    v-model:selection="selectedMenuPrice"
                    @rowSelect="onMenuPriceSelect"
                    dataKey="id"
                >
                    <Column field="id" header="ID" :style="{'min-width':'40px'}"></Column>
                    <Column field="menu_name" header="Menu name" :style="{'min-width':'100px'}"></Column>
                    <Column field="lunch_price" header="Lunch"></Column>
                    <Column field="dinner_price" header="Dinner"></Column>
                </DataTable>
                <div class="p-grid p-mt-5">
                    <div class="p-col">
                        {{ childrenPolicy }}
                    </div>
                    <div class="p-col-fixed">
                        <div style="text-align: right">
                            <span style="font-weight: 500">Menu prices are in USD</span>
                        </div>
                    </div>
                </div>
            </Panel>
        </div>
        <div class="p-col-12 p-md-6 p-lg-6">
            <Panel class="rate-panel" header="Menu dish">
                <div class="menu-dishes">
                    <ScrollPanel style="width: 100%; height: 22rem" class="custom-scrollbar">    
                        <span v-html="menuDishes.dish_desc"></span>
                    </ScrollPanel>
                </div>
            </Panel>
        </div>
    </div>
</div>
</template>

<script>
import { ref, reactive, watchEffect } from 'vue'
import { useToast } from "primevue/usetoast";
import dayjs from 'dayjs';

import ScrollPanel from 'primevue/scrollpanel';
import RestaurantService from '../service/RestaurantService'

export default {
    setup() {
        const toast = useToast()
        const restaurants = ref()
        const selectedRestaurant = ref()
        const filteredRestaurant = ref()
        
        let contract_list = ref([])
        let selectedContract = ref()

        let contractPeriod_List = ref([])
        let selectedPeriod = ref()

        let restaurant_id = ref()

        //On Create
        const restaurantService = ref(new RestaurantService())

        //Methods
        const searchRestaurant = (event) => {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    filteredRestaurant.value = [...restaurants.value];
                }
                else {
                    restaurantService.value.searchRestaurant(event.query.toLowerCase()).then(data => {
                        filteredRestaurant.value = data
                    })
                }
            }, 250);
        }

        const openRestaurantContractForm = () => {
            if(selectedRestaurant.value) {
                window.open('/restaurant/update-contract?restaurantid='+selectedRestaurant.value.id, '_blank');
            }
        }

        const onRestaurantNameSelect = (event) => {
            restaurant_id.value = event.value.id
            getContractList(restaurant_id.value)
            
            contractPeriod_List.value.length = 0
        }

        const getContractList = (restaurantID) => {
            restaurantService.value.getContract(restaurantID).then((data) => {
                if(!data.errorResponse) {
                    contract_list.value = data
                    selectedContract.value = data[0]
                } else {
                    toast.add({severity:'warn', summary: 'Getting Contract Error ' + data.status, detail: data.errorResponse, life: 5000});
                }
            })
        }

        watchEffect(() => {
            if(selectedContract.value) {
                getContractPeriod(selectedContract.value.id)
            }
        })

        function getContractPeriod(contract_id) {
            restaurantService.value.getRestaurantContractPeriod(contract_id).then(data => {
                contractPeriod_List.value = data.map(item => {
                    return {
                        id: item.id,
                        validity: item.valid_from + ' - ' + item.valid_to,
                        rest_contract_id: item.rest_contract_id
                    }
                })

                //Find index in contract period for current year and select dropdown
                if(contractPeriod_List.value) {
                    const data_idx = data.findIndex(item => dayjs(item.valid_from).isSame(dayjs(), 'year'))
                    if(data_idx == -1) {
                        selectedPeriod.value = contractPeriod_List.value[0]
                    } else {
                        selectedPeriod.value = contractPeriod_List.value[data_idx]
                    }
                }
            })
        }

        //==============MENU RATE==============
        let menuPriceList = ref([])
        let selectedMenuPrice = ref()
        let menuDishes = reactive({})
        let childrenPolicy = ref('')

        watchEffect(() => {
            if(selectedPeriod.value) {
                getMenuPrice()
            }
        })

        function getMenuPrice () {
            const periodid = selectedPeriod.value.id
            
            restaurantService.value.getMenuPrice(periodid).then((data) => {
                if(data.length > 0) {
                    menuPriceList.value = data
                } else {
                    menuPriceList.value.length = 0
                }
            })
        }

        const onMenuPriceSelect = () => {
            getMenuDish()
            getChildrenPolicy()
        }

        const getMenuDish = () => {
            const menuID = selectedMenuPrice.value.menu_id

            restaurantService.value.getMenuDish(menuID).then(data => {
                if(!data.errorResponse) {
                    if(data.length == 0) {
                        initMenuDishes()
                    } else {
                        Object.assign(menuDishes, data[0])
                    }
                } else {
                    toast.add({severity:'error', summary: 'Getting Menu Dish Error ' + data.status, detail: data.errorResponse, life: 5000});
                }
            })
        }

        const initMenuDishes = () => {
            const menu_dish = {
                id: '',
                dish_desc: '<p><strong>Starter:</strong></p><p><br></p><p><strong>Soup:</strong></p><p><br></p><p><strong>Main Course:</strong></p><p><br></p><p><strong>Dessert:</strong></p><p><br></p><br>',
                menu_id: 0,
            }

            Object.assign(menuDishes, menu_dish)
        }

        function getChildrenPolicy() {
            const periodid = selectedPeriod.value.id

            restaurantService.value.getContractPolicy('Children Policy', periodid).then(data => {
                if(!data.errorResponse) {
                    if(data.length > 0)  {
                        childrenPolicy.value = data[0].desc
                    }  else {
                        childrenPolicy.value = ''
                    }
                }
            })
        }

        return {
            restaurant_id,
            selectedRestaurant,
            filteredRestaurant,

            contract_list,
            selectedContract,

            contractPeriod_List,
            selectedPeriod,
            searchRestaurant,
            onRestaurantNameSelect,

            openRestaurantContractForm,

            menuPriceList,
            selectedMenuPrice,
            onMenuPriceSelect,
            menuDishes,
            childrenPolicy,
        }
    },
    components: {
        ScrollPanel,
    }
}
</script>

<style lang="scss" scoped>
.p-input-icon-left i {
    z-index: 1;
}

.restaurant-menu .p-panel .p-panel-content {
    height: 25rem;
}

.rate-panel .p-datatable {
    height: 260px;
}

</style>