<template>
<TabView class="custom-tabview"> 
    <TabPanel header="Restaurant List">
        <div class="restaurant-list">
            <div class="search-bar">
                <div class="p-input-icon-left search-field">
                    <i class="pi pi-search" />
                    <InputText type="text" placeholder="Search by name" v-model="filters['global'].value" />
                </div>
                <Button v-if="can('create', 'restaurant')" label="Add" @click="openRestaurantFormDialog('add')" class="p-button-sm" icon="pi pi-plus" />    
            </div>

            <div class="filter-bar">
                <div class="p-fluid p-grid p-field">
                    <label for="role_name" class="p-col-fixed">City/Province:</label>
                    <div class="p-col">
                        <Dropdown v-model="selectedProvinceFilter" :options="filterProvinceDropdown" optionLabel="province_name">
                        </Dropdown>
                    </div>

                    <div class="p-col-fixed filter-buttons">
                        <Button label="Filter" @click="filterRestaurant()" class="p-mr-1 p-button-sm p-button-warning filter-button" icon="pi pi-filter" />
                        <Button label="Clear" @click="clearFilterRestaurant()" class="p-button-sm p-button-secondary filter-button" icon="pi pi-filter-slash" />
                    </div>
                </div>
            </div>
            
            <div class="content-section implementation">
                <div class="restaurant-datatable card">
                    <DataTable
                        :value="restaurantList"
                        responsiveLayout="scroll"
                        scrollHeight="360px"
                        v-model:selection="selectedRestaurant" 
                        selectionMode="single"
                        v-model:filters="filters"
                        :globalFilterFields="['restaurant_name']"
                        @rowSelect="onRowSelect"
                        dataKey="id"
                    >
                        <Column field="id" header="ID" :style="{'min-width':'50px'}"></Column>
                        <Column field="restaurant_name" header="Restaurant name" :style="{'min-width':'200px'}"></Column>
                        <Column field="province" header="City/Province" :style="{'min-width':'120px'}"></Column>
                        <Column field="tel" header="Tel." :style="{'min-width':'105px'}"></Column>
                        <Column field="email" header="Email"></Column>
                        <Column field="website" header="Website"></Column>
                        <Column field="address" header="Address" :style="{'max-width':'220px'}">
                            <template #body="slotProps">
                                <div class="truncate-addr">
                                    {{slotProps.data.address}}
                                </div>
                            </template>
                        </Column>

                        <Column v-if="can('update', 'restaurant') || can('delete', 'restaurant')" :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                            <template #body="slotProps">
                                <Button v-if="can('update', 'restaurant')" icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="openRestaurantFormDialog('update', slotProps.index)"/>
                                <Button v-if="can('delete', 'restaurant')" icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteRestaurant(slotProps.data)" />
                            </template>
                        </Column>
                    </DataTable>
                    <ConfirmDialog></ConfirmDialog>
                </div> 

                <Dialog v-model:visible="showRestaurantFormDialog" :style="{width: '50rem', margin: '0px 5px'}" :modal="true">
                    <template #header>
                        <h5 style="margin: 0px">{{dialogHeader}}</h5>
                    </template>
                    <RestaurantForm :FormType="form_type" @RefreshRestaurantList="getRestaurantsData()" />
                </Dialog>
                
                <div class="tabmenu card">
                    <TabView lazy class="tabview-custom-header sub-tabview">
                        <TabPanel>
                            <template #header>
                                <i class="pi pi-phone"></i>
                                <span>Contact Person</span>
                            </template>
                            <div v-if="displayComponent">
                                <RestaurantContactPerson />
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
        </TabPanel>

    <TabPanel header="Contract Rate">
        <RestaurantContractRate />
    </TabPanel>
</TabView>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import {FilterMatchMode} from 'primevue/api';
import RestaurantForm from '../components/RestaurantForm.vue';
import RestaurantContactPerson from '../components/RestaurantContactPerson.vue';
import RestaurantContractRate from './RestaurantContractRate.vue';

import RestaurantService from '../service/RestaurantService';
import ProvinceService from '../service/CambodiaProvinceService';
export default {
    setup() {
        const { can } = useAbility()
        const store = useStore()
        const confirm = useConfirm()
        const toast = useToast()

        let selectedRestaurant = ref()
        let restaurantList = ref([])
        const displayComponent = ref(false)
        const filters = ref({
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        });
        
        let dialogHeader = ref('')
        let form_type = ref('')
        const showRestaurantFormDialog = ref(false)

        let filterProvinceDropdown = ref([])
        const selectedProvinceFilter = ref()

        //On Create
        const restaurantService = ref(new RestaurantService())
        const provinceService = ref(new ProvinceService())

        onMounted(() => {
            getRestaurantsData()
            getProvinces()
        })

        //Methods
        const onRowSelect = (event) => {
            store.dispatch('setContactPerson_RestID', event.data.id)
            store.dispatch('setIsRestaurantContactList', true)
            displayComponent.value = true
        }

        const getRestaurantsData = () => {
            restaurantService.value.getRestaurants().then(data => {
                if(!data.errorResponse) {
                    restaurantList.value = data
                }
            })
        }

        const getProvinces = () => {
            provinceService.value.getProvinces().then(data => {
                filterProvinceDropdown.value = [{id: 0, province_name: 'Any'}]
                for(const item of data) {
                    filterProvinceDropdown.value = [...filterProvinceDropdown.value, 
                        {id: item.id, province_name: item.province_name}
                    ]
                }
                selectedProvinceFilter.value = filterProvinceDropdown.value[0]
            });
        }

        const openRestaurantFormDialog = (formtype, restaurantindex) => {
            showRestaurantFormDialog.value = true
            form_type.value = formtype
            
            if(formtype == 'add'){
                dialogHeader.value = 'Add Restaurant'
            } else {
                dialogHeader.value = 'Update Restaurant'
                store.dispatch('restaurant_updateform', restaurantList.value[restaurantindex])
            }
        }

        const confirmDeleteRestaurant = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.restaurant_name +'" ?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const restaurantID = data.id
                    restaurantService.value.deleteRestaurant(restaurantID).then((data) => {
                        if(!data.errorResponse){
                            getRestaurantsData()
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});
                        }
                    })
                }
            });
        }

        const filterRestaurant = () => {
            const provinceFilter = selectedProvinceFilter.value

            if(provinceFilter.id == 0){
                getRestaurantsData()
            } else {
                restaurantService.value.filterRestaurant(provinceFilter).then(data => {
                    restaurantList.value = data
                })
            }
        }

        const clearFilterRestaurant = () => {
            getRestaurantsData()
            selectedProvinceFilter.value = filterProvinceDropdown.value[0]
        }

        return {
            can,
            restaurantList,
            selectedRestaurant,
            getRestaurantsData,
            onRowSelect,
            filters,

            filterProvinceDropdown,
            selectedProvinceFilter,

            displayComponent,
            dialogHeader,
            form_type,
            openRestaurantFormDialog,
            showRestaurantFormDialog,

            confirmDeleteRestaurant,
            filterRestaurant,
            clearFilterRestaurant,
        }
    },
    components: {
        RestaurantForm,
        RestaurantContactPerson,
        RestaurantContractRate,
    }
}
</script>

<style lang="scss" scoped>
.restaurant-datatable {
    height: 380px;
}

.tabmenu {
    height: auto;
    min-height: 21.5rem;
    padding: 0px 10px;
}
</style>