import axios from "../axios.instance";

const CancelToken = axios.CancelToken;
let source = '';

export default class RestaurantContactPersonService {
    async getRestContactPerson(id) {        
        try {
            source = CancelToken.source();
            if(id != 0) {
                const res = await axios.get('/rest-contactperson/'+id, {
                    cancelToken: source.token
                })
                return res.data
            }
            // if(res.data.length > 0){
            //     return res.data
            // } else {
            //     return []
            // }
        } catch (error) {
            // console.log(error.message)
            return error.response.data
        }
    }

    cancelRequest() {
        source.cancel('Request Canceled');
    }

    async addRestContactPerson(data) {
        try {
            if(data != ''){
                const res = await axios.post('/rest-contactperson', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateRestContactPerson(contact_person_id, data) {
        try {
            if(contact_person_id != 0){
                const res = await axios.put('/rest-contactperson/'+contact_person_id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteRestContactPerson(contact_person_id) {
        try {
            if(contact_person_id != 0) {
                const res = await axios.delete('/rest-contactperson/'+contact_person_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }
}