<template>
<form @submit.prevent="FormType == 'add' ? addRestaurant() : updateRestaurant()">
    <div class="restaurant-form">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="restaurant_name" class="p-col-fixed">Restaurant name*:</label>
                <div class="p-col">
                    <InputText id="restaurant_name" v-model="restaurantForm.restaurant_name" type="text" :class="{'p-invalid':v$.restaurant_name.$error || !isRestaurantNameValid}" />
                    <small v-if="v$.restaurant_name.$error" class="p-error">{{v$.restaurant_name.required.$message.replace('Value', 'Restaurant name')}}</small>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="city" class="p-col-fixed">City/Province*:</label>
                <div class="p-col">
                    <Dropdown v-model="selectedProvinceDropdown" :options="provinceDropdown" @change="onProvinceChange()" optionLabel="province_name" :class="{'p-invalid':v$.province.$error}">
                    </Dropdown>
                    <small v-if="v$.province.$error" class="p-error">{{v$.province.required.$message.replace('Value', 'Province')}}</small>  
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="tel" class="p-col-fixed">Tel*:</label>
                <div class="p-col">
                    <InputMask v-model="restaurantForm.tel" mask="999 999-999?9" :class="{'p-invalid':v$.tel.$error}"/>
                    <small v-if="v$.tel.$error" class="p-error">{{v$.tel.required.$message.replace('Value', 'Phone number')}}</small>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="email" class="p-col-fixed">Email:</label>
                <div class="p-col">
                    <InputText id="email" v-model="restaurantForm.email" type="text" :class="{'p-invalid':v$.email.$error}"/>
                    <small v-if="v$.email.$error" class="p-error">{{v$.email.$errors[0].$message.replace('Value', 'Email')}}</small>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="website" class="p-col-fixed">Website:</label>
                <div class="p-col">
                    <InputText id="website" v-model="restaurantForm.website" type="text" />
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="address" class="p-col-fixed">Address:</label>
                <div class="p-col">
                    <InputText id="address" v-model="restaurantForm.address" type="text" />
                </div>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-fixed">
                <div v-if="FormType == 'add'">
                    <Button v-if="can('create', 'restaurant')" label="Save" icon="pi pi-save" type="submit" class="p-button-success" />
                </div>
                <div v-else>
                    <Button v-if="can('update', 'restaurant')" label="Update" icon="pi pi-save" type="submit" class="p-button-warning" />
                </div>
            </div>
            <div class="p-col-fixed">
                <InlineMessage v-if="messages.isMsgShow" :severity="messages.severity" class="p-ml-4">{{ messages.content }}</InlineMessage>
            </div>	
        </div>
    </div>
</form>
</template>

<script>
import { ref, reactive, onMounted, computed} from 'vue';
import { useStore } from 'vuex';
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

import RestaurantService from '../service/RestaurantService'
import CambodiaProvinceService from '../service/CambodiaProvinceService'

export default {
    props: {
        FormType: String
    },
    emits: ['RefreshRestaurantList'],
    setup(props, { emit }) {
        const { can } = useAbility()
        const store = useStore()
        const toast = useToast()

        let restaurantForm = reactive({})
        let restaurant_info = reactive({}) //Get data from store to fill in form
        let restaurant_id = ref(0) //Save restaurant id to use in update form
        let isRestaurantNameValid = ref(true)

        let provinceDropdown = ref([])
        const selectedProvinceDropdown = ref()

        let messages =  computed(() => store.state.showMsg)

        //On Create
        const restaurantService = ref(new RestaurantService())
        const provinceService = ref(new CambodiaProvinceService())

        onMounted(() => {
            initRestaurantForm()

            if(props.FormType == 'update') {
                restaurant_info = store.state.restaurantInfo.restaurantUpdateForm
                
                restaurant_id.value = restaurant_info.id
                setProvinceDropdown(restaurant_info.province)
                Object.assign(restaurantForm, restaurant_info)
            } else {
                setProvinceDropdown()
            }
        })

        //Methods
        const initRestaurantForm = () =>{
            const initForm = {
                restaurant_name: '',
                province: '',
                tel: '',
                email: '',
                website: '',
                address: '',
            }

            Object.assign(restaurantForm, initForm)
        }

        const addRestaurant  = () => {
            if(validateForm()) {
                restaurantService.value.addRestaurant(restaurantForm).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            showMessage('warn', data.message, 5000)
                            isRestaurantNameValid.value = false
                        } else {
                            initRestaurantForm()
                            emit('RefreshRestaurantList')
                            showMessage('success', 'Restaurant was added successfully.', 3500)
                            v$.value.$reset() //Reset validations
                            isRestaurantNameValid.value = true
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Save Error ' + data.status, detail: data.errorResponse, life: 5000});
                    }
                })
            }
        }

        const updateRestaurant = () => {
            if(validateForm()) {
                delete restaurantForm.id //DELETE ID FIELD BECAUSE IT DOESN'T NECESSARY TO UPDATE
                
                const update_data = {
                    restaurant_name: restaurantForm.restaurant_name == restaurant_info.restaurant_name ? '': restaurantForm.restaurant_name,
                    province: restaurantForm.province,
                    tel: restaurantForm.tel,
                    email: restaurantForm.email,
                    website: restaurantForm.website,
                    address: restaurantForm.address,
                }
                restaurantService.value.updateRestaurant(restaurant_id.value, update_data).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400){
                            showMessage('warn', data.message, 5000)
                            isRestaurantNameValid.value = false
                        } else {
                            showMessage('success', 'Restaurant was updated successfully.', 3500)
                            emit('RefreshRestaurantList')
                            isRestaurantNameValid.value = true
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Update Error ' + data.status, detail: data.errorResponse, life: 5000});
                    }
                })
            }
        }
        
        const setProvinceDropdown = async (province_name) => {
            await provinceService.value.getProvinces().then(data => provinceDropdown.value = data)

            if (province_name != ''){
                const province = provinceDropdown.value.filter(item => item.province_name == province_name)
                selectedProvinceDropdown.value = province[0]
            }
        }

        const showMessage = (severity, content, life) => {
            let message = {
                isMsgShow: true, 
                severity: severity, 
                content: content
            }

            store.dispatch('showMsg', message)
            if(life) {
                setTimeout(() => {
                    store.dispatch('showMsg', { isMsgShow: false, severity: '', content: '' })
                }, life);
            }
        }

        const onProvinceChange = () => {
            restaurantForm.province = selectedProvinceDropdown.value.province_name
        }

        //Form Validations
        let rules = computed(() =>  {
            return {
                restaurant_name: { required },
                province: { required },
                tel: { required },
                email: { email },
            }
        })
        const v$ = useVuelidate(rules, restaurantForm)
        const validateForm = () => {
            v$.value.$validate();
            if(!v$.value.$error){
                return true
            } else {
                return false
            }
        }

        return {
            can,
            v$,
            restaurantForm,
            addRestaurant,
            updateRestaurant,
            isRestaurantNameValid,

            provinceDropdown,
            selectedProvinceDropdown,
            onProvinceChange,

            messages,
        }
    },
}
</script>

<style lang="scss" scoped>
.restaurant-form {
    margin-top: 5px;

    label {
        width: 130px;
    }
}
</style>